import React, { FC, useContext, useMemo } from 'react';

import { Auth0Client, IdToken } from '@auth0/auth0-spa-js';
import { v4 as uuidV4 } from 'uuid';

import { AuthProvider } from '../contexts/AuthContext';
import { CompilerContext, CompilerContextState } from '../contexts/CompilerContext';

import { useMutation, useQuery } from 'react-query';

export const AuthWrapper: FC = ({ children }) => {
  const { config } = useContext<CompilerContextState>(CompilerContext);
  const { router, auth, mode, projectId } = config;

  const auth0SpaClient = useMemo(() => {
    const { isCustomDomain, customDomain } = config.router;

    const redirect_uri = isCustomDomain
      ? `https://${customDomain}/callback`
      : `${process.env.GSTUDIO_DEPLOYMENT_URL}/deploy/project/${projectId}/callback`;

    return new Auth0Client({
      domain: auth.domain,
      client_id: auth.clientId,
      redirect_uri,
      auth0Client: {
        name: `auth-wrapper-${projectId}`,
        version: uuidV4(),
      },
    });
  }, [projectId]);

  const userQuery = useQuery<any, { error: string; error_description: string }>(
    ['applicationUser'],
    async () => {
      await auth0SpaClient.getTokenSilently();

      const userInfo = await auth0SpaClient.getUser();

      return userInfo;
    },
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  const loginWithRedirect = useMutation(['authWrapper', 'loginWithRedirect'], async (): Promise<void> => {
    localStorage.setItem('redirectRoute', router.currentRoute);

    await auth0SpaClient.loginWithRedirect();
  });

  const logout = useMutation(['authWrapper', 'logout'], async (): Promise<void> => {
    localStorage.removeItem('redirectRoute');

    await auth0SpaClient.logout({ returnTo: process.env.GSTUDIO_DEPLOYMENT_URL + '/home' });
    await userQuery.remove();
  });

  const getIdTokenClaims = useMutation(
    ['authWrapper', 'getIdTokenClaims'],
    async (): Promise<IdToken | undefined> => {
      return auth0SpaClient.getIdTokenClaims();
    },
  );

  const handleRedirectCallback = useMutation(
    ['authWrapper', 'handleAuth0Callback'],
    async (): Promise<any> => {
      await auth0SpaClient.handleRedirectCallback(window.location.href);

      userQuery.refetch();
    },
    {
      onSuccess() {
        const redirectRoute = localStorage.getItem('redirectRoute');
        router.push({ path: redirectRoute });
      },
      onError(err) {
        console.error(err);
        router.push({ path: '/401' });
      },
    },
  );

  if (mode !== 'production') {
    return <>{children}</>;
  }

  return (
    <AuthProvider
      value={{
        user: userQuery.data,
        isLoading: userQuery.isLoading || userQuery.isIdle || handleRedirectCallback.isLoading,
        isAuthenticated: !!userQuery.data,
        loginWithRedirect,
        logout,
        refetchUser: userQuery,
        handleRedirectCallback,
        getIdTokenClaims,
      }}
    >
      {children}
    </AuthProvider>
  );
};
