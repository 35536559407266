import React, { FC, useMemo } from 'react';

import { cloneDeep, findIndex } from 'lodash';

import { CompiledBlock } from '@application/compiler/CompiledBlock';
import { recursiveFindParentElement } from '@application/lib/nodeTree/nodeTreeUtils';

import { ComposerLayout, ComposerPage } from '@types';

interface PageCompilerProps {
  page: ComposerPage;
  layouts: ComposerLayout[];
}

export const PageCompiler: FC<PageCompilerProps> = ({ page, layouts }) => {
  // Currently we only have 1 layout
  const [layout] = layouts;

  const nodesStructure = useMemo(() => {
    if (!layout) return page.nodesStructure;

    const pageNodeStructure = cloneDeep(page.nodesStructure);
    const layoutNodeStructure = cloneDeep(layout.nodesStructure);

    const layoutRouterViewParent = recursiveFindParentElement(layoutNodeStructure, layout.id);
    if (!layoutRouterViewParent) return pageNodeStructure;

    const routerViewIndex = findIndex(layoutRouterViewParent.children, { id: layout.id });

    if (routerViewIndex < 0) return pageNodeStructure;

    layoutRouterViewParent.children[routerViewIndex].children = cloneDeep(pageNodeStructure.children);

    pageNodeStructure.children = [layoutNodeStructure];

    return pageNodeStructure;
  }, [layout, page]);

  return <CompiledBlock node={nodesStructure} />;
};
