import { cloneDeep } from 'lodash';
import { DynamicPropsProvider } from '../contexts/DynamicDataContext';
import React, { FC, useState } from 'react';

export const DynamicPropsWrapper: FC<{}> = ({ children }) => {
  const [dynamicData, setDynamicData] = useState({});

  function addDynamicDataProperty(newDynamicData: Record<string, any>) {
    if (!newDynamicData) return;

    setDynamicData((prevState) => {
      return {
        ...prevState,
        ...newDynamicData,
      };
    });
  }

  function deleteDynamicDataProperty(id: string) {
    const newDynamicData: Record<string, any> = cloneDeep(dynamicData);

    if (!newDynamicData[id]) return;

    // @ts-ignore
    delete newDynamicData[id];

    setDynamicData(newDynamicData);
  }

  return (
    <DynamicPropsProvider value={{ data: dynamicData, addDynamicDataProperty, deleteDynamicDataProperty }}>
      {children}
    </DynamicPropsProvider>
  );
};
