import React, { FC } from 'react';

import { Box, Flex } from '@chakra-ui/react';

import { Button, Heading, Text } from '@application/components/ds';

import { Deployment } from '@types';

interface DeploymentNotFoundProps {
  deployment: Deployment;
}

export const DeploymentNotFound: FC<DeploymentNotFoundProps> = ({ deployment, children }) => {
  if (!deployment) {
    return (
      <Flex height="100vh" width="100vw" flexDirection="column" justifyContent="center" alignItems="center">
        <Heading variant="title" color="ui.800">
          Oops!
        </Heading>
        <Text mb="sm">Deployment not found</Text>
        <a href="https://gstudio.app">
          <Button size="sm">Take me to gStudio</Button>
        </a>
      </Flex>
    );
  }

  return (
    <Box height="100vh" width="100vw">
      {children}
    </Box>
  );
};
