import React, { FC, useContext } from 'react';

import { Flex as ChakraFlex } from '@chakra-ui/react';

import { Button, Heading, Text } from '@application/components/ds';
import { CompilerContext } from '@application/compiler/contexts';
import { LayoutEmpty } from '@application/layouts/LayoutEmpty';

export const UnauthorizedPage: FC = () => {
  const { config } = useContext(CompilerContext);

  return (
    <LayoutEmpty title="Unauthorized">
      <ChakraFlex
        width="100vw"
        height="100vh"
        textAlign="center"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        backgroundImage="radial-gradient(var(--chakra-colors-ui-200) 1px, transparent 0)"
        backgroundSize="8px 8px"
        backgroundPosition="-19px -19px"
      >
        <Heading variant="title">401</Heading>
        <Heading variant="subtitle">You are unauthorized to access this page!</Heading>
        <Text marginY="sm" color="ui.700">
          Contact the administrator to handle this issue! If the <br />
          problem persists, contact the gStudio support team!
        </Text>
        <Button colorScheme="pt-primary" marginY="md" onClick={() => config.router.push({ path: '/' })}>
          Take me to home
        </Button>
      </ChakraFlex>
    </LayoutEmpty>
  );
};
