import React, { FC, useContext } from 'react';

import { Flex as ChakraFlex } from '@chakra-ui/react';
import { Route, MemoryRouter as Router, Switch } from 'react-router-dom';

import { ProjectCompilerProps } from '@types';

import { CompilerContext } from '@application/compiler/contexts';

import { CallbackPage } from './CallbackPage';
import { EditorLocationBar } from './EditorLocationBar';
import { PageCompiler } from './PageCompiler';
import { UnauthorizedPage } from './UnathorizedPage';

import { AuthWrapper } from '../wrappers/AuthWrapper';

export const ProjectCompiler: FC<ProjectCompilerProps> = ({ pages, layouts }) => {
  if (typeof window === 'undefined') return null;

  const { config } = useContext(CompilerContext);

  return (
    <ChakraFlex height="100%" background="white" flexDirection="column" overflow="auto">
      <Router>
        <AuthWrapper>
          {/* It handles itself on handling development/production */}
          <Route path="*" render={(props) => <EditorLocationBar {...props} pages={pages} />} />

          <Switch
            location={{
              hash: '',
              search: '',
              state: {},
              pathname: config.router.currentRoute,
            }}
          >
            <Route exact={true} path="/callback" render={() => <CallbackPage />} />
            <Route exact={true} path="/unathorized" render={() => <UnauthorizedPage />} />

            {pages.map((page) => {
              return (
                <Route
                  exact={true}
                  key={page.id}
                  path={page.path}
                  render={() => <PageCompiler page={page} layouts={layouts} />}
                />
              );
            })}
          </Switch>
        </AuthWrapper>
      </Router>
    </ChakraFlex>
  );
};
