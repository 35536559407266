import React, { FC } from 'react';

import { ToastContainer } from 'react-toastify';
import Head from 'next/head';

interface LayoutEmptyProps {
  title?: string;
}

export const LayoutEmpty: FC<LayoutEmptyProps> = ({ title, children }) => (
  <>
    <Head>
      <title>{`gStudio - ${title}` || 'gStudio'}</title>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <link rel="preconnect" href="https://fonts.gstatic.com"></link>
    </Head>
    {children}
    <ToastContainer />
  </>
);
