import { EngineProvider } from '../contexts/EngineContext';
import React, { FC, useMemo } from 'react';

import { Engine } from 'json-logical-engine';

export const EngineWrapper: FC<{}> = ({ children }) => {
  const engine = useMemo(() => new Engine({}, {}), []);

  return (
    <EngineProvider key="engineProvider" value={{ engine }}>
      {children}
    </EngineProvider>
  );
};
