import React, { FC, useContext, useEffect, useMemo } from 'react';

import { Badge as ChakraBadge, Flex as ChakraFlex, Switch as ChakraSwitch } from '@chakra-ui/react';
import { matchPath, RouteComponentProps } from 'react-router-dom';

import { ComposerPage } from '@types';

import { EditorContext } from '@application/components/pages/Project/Editor/context';
import { Text } from '@application/components/ds';

import { CompilerContext } from '../contexts';

interface Props extends RouteComponentProps {
  pages: ComposerPage[];
}

export const EditorLocationBar: FC<Props> = ({ history, pages }) => {
  const { config } = useContext(CompilerContext);
  const { isInspectModeActive, onToggleInspectMode } = useContext(EditorContext);

  const { router, mode } = config;

  const isEditorMode = mode === 'edit';

  const getCurrentPage = useMemo(() => {
    return pages?.find(({ path }) => matchPath(router.currentRoute, { path, exact: true }));
  }, [pages, router.currentRoute]);

  useEffect(() => {
    history.location.pathname = router.currentRoute;
  }, [history, router.currentRoute]);

  if (!isEditorMode) return null;

  return (
    <ChakraFlex
      paddingY="sm"
      paddingX="md"
      borderBottom="sm"
      borderColor="ui.200"
      justifyContent="space-between"
      alignItems="center"
    >
      <ChakraFlex justifyContent="start">
        <Text variant="body-1" fontWeight="bold" color="ui.800">
          Page:
        </Text>
        <Text marginLeft="sm" variant="body-1" color="ui.700">
          {history.location.pathname}
        </Text>
      </ChakraFlex>

      <ChakraFlex justifyContent="end" alignItems="center" gap="md">
        <ChakraFlex justifyContent="start" alignItems="center" gap="sm">
          <Text color="ui.700" variant="body-1">
            Inspect
          </Text>
          <ChakraSwitch size="sm" isChecked={isInspectModeActive} onChange={onToggleInspectMode} />
        </ChakraFlex>

        <ChakraBadge size="sm" fontSize="0.70rem" variant="solid" borderRadius="xs" colorScheme="primary">
          {getCurrentPage?.isProtected ? 'Private' : 'Public'}
        </ChakraBadge>
      </ChakraFlex>
    </ChakraFlex>
  );
};
