import React, { FC, useContext, useEffect } from 'react';

import { Flex as ChakraFlex } from '@chakra-ui/react';

import { Heading, Text, ThemeProvider } from '@application/components/ds';

import { AuthContext } from '../contexts/AuthContext';

export const CallbackPage: FC = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.handleRedirectCallback.mutate({});
  }, []);

  return (
    <ThemeProvider>
      <ChakraFlex
        width="100%"
        height="100%"
        gridGap="sm"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <Heading color="ui.900">Redirecting...</Heading>
        <Text color="ui.700">You should be redirected soon!</Text>
      </ChakraFlex>
    </ThemeProvider>
  );
};
